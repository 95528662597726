import Vue from 'vue'
import VueRouter from 'vue-router'


import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/PastRounds.vue'),
      meta: {
        pageTitle: 'Partidas Anteriores',
        action: 'read',
        resource: 'rounds',
        breadcrumb: [
          {
            text: 'Partidas Anteriores',
            active: true,
          },
        ],
      },
    },
    {
      path: '/prev-rounds',
      name: 'prev-rounds',
      component: () => import('@/views/PastRounds.vue'),
      meta: {
        pageTitle: 'Partidas Anteriores',
        action: 'read',
        resource: 'rounds',
        breadcrumb: [
          {
            text: 'Partidas Anteriores',
            active: true,
          },
        ],
      },
    },
    {
      path: '/next-rounds',
      name: 'next-rounds',
      component: () => import('@/views/NextRounds.vue'),
      meta: {
        pageTitle: 'Próximas Partidas',
        action: 'read',
        resource: 'rounds',
        breadcrumb: [
          {
            text: 'Próximas Partidas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/NotAuthorized.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/current-round',
      name: 'current-round',
      component: () => import('@/views/CurrentRound.vue'),
      meta: {
        pageTitle: 'Rodada em andamento',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Rodada em andamento',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pages/not-authorized',
      name: 'not-authorized',
      // ! Update import path
      component: () => import('@/views/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: '/',
    },

    {
      path: '/round/edit/:roundId',
      name: 'edit-round',
      component: () => import('@/views/RoundEdit.vue'),
      meta: {
        pageTitle: 'Partida',
        breadcrumb: [
          {
            text: 'Edição de partida',
            active: true,
          },
        ],
      },
    },
    {
      path: '/round-summary/:roundId',
      name: 'edit-round',
      component: () => import('@/views/RoundSummary.vue'),
      meta: {
        pageTitle: 'Resumo da rodada',
        action: 'read',
        resource: 'rounds',        
        breadcrumb: [
          {
            text: 'Resumo da rodada',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/dashboard',
    //   name: 'dashboard',
    //   component: () => import('@/views/Dashboard.vue'),
    //   meta: {
    //     pageTitle: 'Painel de controle',
    //     breadcrumb: [
    //       {
    //         text: 'Painel de controle',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/chat-moderator-page',
      name: 'chat-moderator-page',
      component: () => import('@/views/ChatModeratorPage.vue'),
      meta: {
        pageTitle: 'Chat Moderator',
        action: 'read',
        resource: 'moderable',
        breadcrumb: [
          {
            text: 'Chat Moderator',
            active: true,
          },
        ],
      },
    },    
    {
      path: '/create-poll',
      name: 'create-poll',
      component: () => import('@/views/CreatePoll.vue'),
      meta: {
        pageTitle: 'Criar enquete',
        breadcrumb: [
          {
            text: 'Criar enquete',
            active: true,
          },
        ],
      },
    },
    {
      path: '/chat-list-word-blacklist-page',
      name: 'chat-list-word-blacklist-page',
      component: () => import('@/views/ChatListWordBlacklistPage.vue'),
      meta: {
        pageTitle: 'Chat List Words Blacklist',
        action: 'read',
        resource: 'moderable',
        breadcrumb: [
          {
            text: 'Chat List Words Blacklist',
            active: true,
          },
        ],
      },
    },    
    {
      path: '/next-polls',
      name: 'next-polls',
      component: () => import('@/views/NextPolls.vue'),
      meta: {
        pageTitle: 'Próximas enquetes',
        breadcrumb: [
          {
            text: 'Próximas enquetes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/chat-new-word-blacklist-page',
      name: 'chat-new-word-blacklist-page',
      component: () => import('@/views/NewWordBlackListPage.vue'),
      meta: {
        pageTitle: 'New Word BlackList',
        action: 'read',
        resource: 'moderable',
        breadcrumb: [
          {
            text: 'New Word BlackList',
            active: true,
          },
        ],
      },
    },    
    {
      path: '/prev-polls',
      name: 'prev-polls',
      component: () => import('@/views/PrevPolls.vue'),
      meta: {
        pageTitle: 'Enquetes anteriores',
        breadcrumb: [
          {
            text: 'Enquetes anteriores',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rush-hour',
      name: 'rush-hour',
      component: () => import('@/views/RushHour.vue'),
      meta: {
        pageTitle: 'Hora do rush',
        breadcrumb: [
          {
            text: 'Enquetes',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/users-page',
    //   name: 'users-page',
    //   component: () => import('@/views/UserListPage.vue'),
    //   meta: {
    //     pageTitle: 'User List',
    //     breadcrumb: [
    //       {
    //         text: 'Users',
    //         active: true,
    //       },
    //     ],
    //   },
    // },    
    {
      path: '/poll/edit/:pollId',
      name: 'poll-edit',
      component: () => import('@/views/EditPoll.vue'),
      meta: {
        pageTitle: 'Enquete',
        breadcrumb: [
          {
            text: 'Edição de enquete',
            active: true,
          },
        ],
      },
    },
    {
      path: '/poll/current-poll',
      name: 'current-poll',
      component: () => import('@/views/CurrentPoll.vue'),
      meta: {
        pageTitle: 'Enquete ativa',
        breadcrumb: [
          {
            text: 'Enquete ativa',
            active: true,
          },
        ],
      },
    },
    {
      path: '/poll/poll-summary/:pollId',
      name: 'poll-summary',
      component: () => import('@/views/PollSummary.vue'),
      meta: {
        pageTitle: 'Enquete anterior',
        breadcrumb: [
          {
            text: 'Enquete anterior',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/list',
      name: 'users-list',
      component: () => import('@/views/UsersList.vue'),
      meta: {
        pageTitle: 'Usuários',
        breadcrumb: [
          {
            text: 'Usuários',
            active: true,
          },
        ],
      },
    },
        
    {
      path: '/create-user',
      name: 'create-user',
      component: () => import('@/views/CreateUser.vue'),
      meta: {
        pageTitle: 'Criar Usuario',
        breadcrumb: [
          {
            text: 'Criar Usuario',
            active: true,
          },
        ],
      },
    },
    {
      path: '/stack-users',
      name: 'stack-users',
      component: () => import('@/views/PlatUsers.vue'),
      meta: {
        pageTitle: 'Usuários site',
        action: 'read',
        resource: 'chat-attendant',
        breadcrumb: [
          {
            text: 'Usuários site',
            active: true,
          },
        ],
      },
    },
    {
      path: '/client-single/:clientId',
      name: 'client-single',
      component: () => import('@/views/ClientSingle.vue'),
      meta: {
        pageTitle: 'Detalhe usuário',
        action: 'read',
        resource: 'chat-attendant',
        breadcrumb: [
          {
            text: 'Detalhe usuário',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/last-deposits',
    //   name: 'last-deposits',
    //   component: () => import('@/views/LastDeposits.vue'),
    //   meta: {
    //     pageTitle: 'Últimos depositos',
    //     action: 'read',
    //     resource: 'chat-attendant',
    //     breadcrumb: [
    //       {
    //         text: 'Últimos depositos',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/last-withdraws',
    //   name: 'last-withdraws',
    //   action: 'read',
    //   resource: 'chat-attendant',
    //   component: () => import('@/views/LastWithdraws.vue'),
    //   meta: {
    //     pageTitle: 'Últimos saques',
    //     action: 'read',
    //     resource: 'chat-attendant',
    //     breadcrumb: [
    //       {
    //         text: 'Últimos saques',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/master-users',
    //   name: 'master-users',
    //   component: () => import('@/views/MasterUsers.vue'),
    //   meta: {
    //     pageTitle: 'Meus usuários',
    //     action: 'read',
    //     resource: 'master',
    //     breadcrumb: [
    //       {
    //         text: 'Meus usuários',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/master-transactions',
    //   name: 'master-transactions',
    //   component: () => import('@/views/MasterLastTransactions.vue'),
    //   meta: {
    //     pageTitle: 'Minhas transações',
    //     action: 'read',
    //     resource: 'master',
    //     breadcrumb: [
    //       {
    //         text: 'Minhas transaçoes',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
  ],
})

router.beforeEach((to, _, next) => {
  let uData = JSON.parse(localStorage.getItem('userData'));
  if(uData && uData.expires?.length > 0){
    let expires = new Date(uData.expires);
    let now = new Date();
    if(expires < now){
      window.localStorage.removeItem('userData');
    }
  }else{
    window.localStorage.removeItem('userData');
  }

  const isLoggedIn = isUserLoggedIn();

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    // ! We updated login route name here from `auth-login` to `login` in starter-kit
    console.log('cant');
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser())
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
